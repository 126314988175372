import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import HomePage from "views/HomePage/HomePage";
import Layout from "views/Layout";
import PatientServicesPage from "views/PatientServicesPage/PatientServicesPage";
import ReferralsPage from "views/ReferralsPage/ReferralsPage";
import ProstheticsPage from "views/ProstheticsPage/ProstheticsPage";
import OrthoticsPage from "views/OrthoticsPage/OrthoticsPage";
import GetStartedPage from "views/GetStartedPage/GetStartedPage";
import AboutUsPage from "views/AboutUsPage/AboutUsPage";
import ContactUsPage from "views/ContactUsPage/ContactUsPage";
import LocationsPage from "views/LocationsPage/LocationsPage";
import JacksonLocationsPage from "views/LocationsPage/JacksonLocationPage";
import HattiesburgLocationsPage from "views/LocationsPage/HattiesburgLocationPage";
import VicksburgLocationsPage from "views/LocationsPage/VicksburgLocationPage";
import GreenwoodLocationsPage from "views/LocationsPage/GreenwoodLocationPage";
import BatonRougeLocationPage from "views/LocationsPage/BatonRougeLocationPage";
import ShreveportLocationPage from "views/LocationsPage/ShreveportLocationPage";
import PrivacyPolicyPage from "views/PrivacyPolicyPage/PrivacyPolicyPage";
import ProstheticSupplyPage from "views/ProstheticSupplyPage/ProstheticSupplyPage";
import ProfilePage from "views/AboutUsPage/ProfilePage";
import instance from "analytics/AnalyticsInitializer";
import { AnalyticsProvider, usePage } from "use-analytics";
import { GoogleReCaptchaProvider } from "google/context";
//import PresentationPage from "views/PresentationPage/PresentationPage";
//import SectionsPage from "views/SectionsPage/SectionsPage";
//import ComponentsPage from "views/ComponentsPage/ComponentsPage.js";

const AppRoutes = () => {
  let location = useLocation();
  const page = usePage();

  React.useEffect(() => {
    page();
  }, [location, page]);
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="about">
          <Route index element={<AboutUsPage />} />
          <Route path="zach" element={<ProfilePage person="zach" />} />
          <Route path="stephen" element={<ProfilePage person="fin" />} />
          <Route path="rick" element={<ProfilePage person="rick" />} />
          <Route path="brittany" element={<ProfilePage person="brittany" />} />
          <Route path="kaitlyn" element={<ProfilePage person="kaitlyn" />} />
          <Route path="gates" element={<ProfilePage person="gates" />} />
          <Route path="walker" element={<ProfilePage person="walker" />} />
        </Route>
        <Route path="contact" element={<ContactUsPage />} />
        <Route path="patient-services" element={<PatientServicesPage />} />
        <Route path="prosthetics" element={<ProstheticsPage />} />
        <Route path="prosthetic-supply" element={<ProstheticSupplyPage />} />
        <Route path="orthotics" element={<OrthoticsPage />} />
        <Route path="referrals" element={<ReferralsPage />} />
        <Route path="get-started" element={<GetStartedPage />} />
        <Route path="locations" element={<Outlet />}>
          <Route index element={<LocationsPage />} />
          <Route path="jackson" element={<JacksonLocationsPage />} />
          <Route path="hattiesburg" element={<HattiesburgLocationsPage />} />
          <Route path="vicksburg" element={<VicksburgLocationsPage />} />
          <Route path="greenwood" element={<GreenwoodLocationsPage />} />
          <Route path="baton-rouge-la" element={<BatonRougeLocationPage />} />
          <Route path="shreveport-la" element={<ShreveportLocationPage />} />
        </Route>
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        {/**
        <Route path="sections" element={<SectionsPage />} />
        <Route path="components" element={<ComponentsPage />} />
        <Route path="presentation" element={<PresentationPage />} />
         */}
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  return (
    <GoogleReCaptchaProvider
      type="v3"
      siteKey={`${process.env.REACT_APP_GRECAPTCHA_KEY}`}
    >
      <AnalyticsProvider instance={instance}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </AnalyticsProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
