/*eslint-disable*/
import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import locationsStyle from "assets/jss/material-kit-pro-react/views/locationsStyle.js";

const useStyles = makeStyles(locationsStyle);

const BaseLocationsMap = withScriptjs(
  withGoogleMap(({ zoom, center }) => {
    const classes = useStyles();
    return (
      <GoogleMap
        defaultZoom={zoom ?? 15}
        defaultCenter={center}
        defaultOptions={{
          disableDefaultUI: true,
          streetViewControl: false,
          mapTypeControl: false,
          scrollwheel: false,
          zoomControl: true,
          styles: [
            {
              featureType: "water",
              stylers: [
                { saturation: 43 },
                { lightness: -11 },
                { hue: "#0088ff" },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                { hue: "#ff0000" },
                { saturation: -100 },
                { lightness: 99 },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.stroke",
              stylers: [{ color: "#808080" }, { lightness: 54 }],
            },
            {
              featureType: "landscape.man_made",
              elementType: "geometry.fill",
              stylers: [{ color: "#ece2d9" }],
            },
            {
              featureType: "poi.park",
              elementType: "geometry.fill",
              stylers: [{ color: "#ccdca1" }],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [{ color: "#767676" }],
            },
            {
              featureType: "road",
              elementType: "labels.text.stroke",
              stylers: [{ color: "#ffffff" }],
            },
            { featureType: "poi", stylers: [{ visibility: "off" }] },
            {
              featureType: "landscape.natural",
              elementType: "geometry.fill",
              stylers: [{ visibility: "on" }, { color: "#b8cb93" }],
            },
            { featureType: "poi.park", stylers: [{ visibility: "on" }] },
            {
              featureType: "poi.sports_complex",
              stylers: [{ visibility: "on" }],
            },
            { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
            {
              featureType: "poi.business",
              stylers: [{ visibility: "simplified" }],
            },
          ],
        }}
      >
        <MarkerWithLabel
          position={{ lat: 30.39965980157949, lng: -91.10203373058944 }}
          labelAnchor={new google.maps.Point(-16, 32)}
        >
          <div className={classes.markerLabel}>
            <h6>Buckner Prosthetics Baton Rouge</h6>
          </div>
        </MarkerWithLabel>
        <MarkerWithLabel
          position={{ lat: 32.48858438001536, lng: -93.74580063558192 }}
          labelAnchor={new google.maps.Point(-16, 32)}
        >
          <div className={classes.markerLabel}>
            <h6>Buckner Prosthetics Shreveport</h6>
          </div>
        </MarkerWithLabel>
        <MarkerWithLabel
          position={{ lat: 32.33168592202682, lng: -90.13272973381987 }}
          labelAnchor={new google.maps.Point(-16, 32)}
        >
          <div className={classes.markerLabel}>
            <h6>Buckner Prosthetics & Orthotics</h6>
          </div>
        </MarkerWithLabel>
        <MarkerWithLabel
          position={{ lat: 31.280955138590123, lng: -89.32220902114095 }}
          labelAnchor={new google.maps.Point(-16, 32)}
        >
          <div className={classes.markerLabel}>
            <h6>Buckner Prosthetic & Orthotic Laboratories - Hattiesburg</h6>
          </div>
        </MarkerWithLabel>
        <MarkerWithLabel
          position={{ lat: 32.321980470041524, lng: -90.86585354547312 }}
          labelAnchor={new google.maps.Point(-16, 32)}
        >
          <div className={classes.markerLabel}>
            <h6>Buckner Prosthetics & Orthotic Laboratories - Vicksburg</h6>
          </div>
        </MarkerWithLabel>
        <MarkerWithLabel
          position={{ lat: 33.51690428745283, lng: -90.20045991805209 }}
          labelAnchor={new google.maps.Point(-16, 32)}
        >
          <div className={classes.markerLabel}>
            <h6>Buckner Prosthetic & Orthotic Laboratories - Greenwood</h6>
          </div>
        </MarkerWithLabel>
      </GoogleMap>
    );
  })
);

export const LocationsMap = ({ center, zoom }) => (
  <BaseLocationsMap
    zoom={zoom}
    center={center}
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_API_KEY}`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={
      <div
        style={{
          height: `100%`,
          borderRadius: "6px",
          overflow: "hidden",
        }}
      />
    }
    mapElement={<div style={{ height: `100%` }} />}
  />
);
