export const locationKeys = {
  jackson: "jackson",
  hattiesburg: "hattiesburg",
  vicksburg: "vicksburg",
  greenwood: "greenwood",
  batonRouge: "baton-rouge-la",
  shreveport: "shreveport-la",
};

const allLocations = [
  {
    key: "jackson",
    slug: "jackson",
    name: "Jackson, MS",
    phone: "(601) 944-1130",
    fax: "(601) 355-7476",
    address: ["2089 Lakeland Drive", "Jackson, MS 39216"],
    hours: [
      "Mon - Thurs, 8:00 a.m. - 5:00 p.m.",
      "Friday, 8:00 a.m. - 3:00 p.m.",
    ],
    mapLink:
      "https://maps.google.com/maps/dir//Buckner+Prosthetics+%26+Orthotics+2089+Lakeland+Dr+Jackson,+MS+39216/@32.3315409,-90.1326439,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x86283263a0368e2f:0x8480b78d3f1c0d74",
    mapCenter: { lat: 32.3315409, lng: -90.1326439 },
  },
  {
    key: "hattiesburg",
    slug: "hattiesburg",
    name: "Hattiesburg, MS",
    phone: "(601) 336-4687",
    fax: "(601) 336-6782",
    address: ["20 Parkway Blvd.", "Hattiesburg, MS 39401"],
    hours: [
      "Mon - Thurs, 8:00 a.m. - 5:00 p.m.",
      "Friday, 8:00 a.m. - 3:00 p.m.",
    ],
    mapLink:
      "https://www.google.com/maps/dir//Buckner+Prosthetic+%26+Orthotic+Laboratories+Inc+Hattiesburg+Office+20+Parkway+Blvd+Hattiesburg,+MS+39401/@31.2808383,-89.3227707,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x889cdde05490675d:0xc5e3f5ab6a8f235",
    mapCenter: { lat: 31.2808383, lng: -89.3227707 },
  },
  {
    key: "vicksburg",
    slug: "vicksburg",
    name: "Vicksburg, MS",
    phone: "(601) 944-1130",
    fax: "(601) 355-7476",
    address: ["2080 South Frontage Rd,", "Suite A", "Vicksburg, MS 39180"],
    hours: ["Wednesday, 9:00 a.m. - 4:00 p.m."],
    mapLink:
      "https://www.google.com/maps/dir//2080+S+Frontage+Rd+Ste+100,+Vicksburg,+MS+39180/@32.3218832,-90.8684476,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x8628e1b31d632c13:0xbfcb17f57c1e0963!2m2!1d-90.8658369!2d32.3219801!3e0?entry=ttu",
    mapCenter: { lat: 32.3218832, lng: -90.8684476 },
  },
  {
    key: "greenwood",
    slug: "greenwood",
    name: "Greenwood, MS",
    phone: "(601) 944-1130",
    fax: "(601) 355-7476",
    address: ["1603 Strong Ave.", "Greenwood, MS 38930"],
    hours: ["Thursdays, 9:00 a.m. - 4:00 p.m."],
    mapLink:
      "https://www.google.com/maps/dir//Buckner+Prosthetics+and+Orthotics,+1603+Strong+Ave+A,+Greenwood,+MS+38930/@32.9857315,-90.6655477,9z/data=!4m9!4m8!1m0!1m5!1m1!1s0x862a6d94ede17681:0x96d6ba4342871eb1!2m2!1d-90.2004557!2d33.5168406!3e0?entry=ttu",
    mapCenter: { lat: 33.516845, lng: -90.2030306 },
  },
  {
    key: "baton-rouge-la",
    slug: "baton-rouge-la",
    name: "Baton Rouge, LA",
    phone: "(225) 233-2743",
    fax: "(225) 233-2745",
    address: ["8110 Summa Ave.", "Baton Rouge, LA 70809"],
    hours: [
      "Mon - Thurs, 8:00 a.m. - 5:00 p.m.",
      "Friday, 8:00 a.m. - 3:00 p.m.",
    ],
    aptOnly: true,
    mapLink:
      "https://www.google.com/maps/dir//Buckner+8110+Summa+Ave,+Baton+Rouge,+LA+70809/@30.3991276,-91.1027797,18.26z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8626a45bd47f06f3:0x32686fb715e70f9d!2m2!1d-91.1020379!2d30.3996074",
    mapCenter: { lat: 30.3991276, lng: -91.1027797 },
  },
  {
    key: "shreveport-la",
    slug: "shreveport-la",
    name: "Shreveport, LA",
    phone: "(225) 233-2743",
    fax: "(225) 233-2745",
    address: ["2405 Line Ave.", "Shreveport, LA 71104"],
    hours: [
      "In-home only during January 2025",
      "New location opens February 15, 2025",
    ],
    aptOnly: true,
    extra: "Now servicing Shreveport and surrounding area",
    mapLink:
      "https://maps.google.com/maps/dir//2405+Line+Ave+Shreveport,+LA+71104/@32.488538,-93.7458059,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x8636cd53746a82a1:0x91d6ab11cb166ca",
    mapCenter: { lat: 32.488538, lng: -93.7458059 },
  },
];

export default allLocations;
