/*!

=========================================================
* Material Kit PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Explore from "@material-ui/icons/Explore";
// core components
import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

export default function Announcements() {
  const [viewPopup, setViewPopup] = React.useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("showLocationsAnnouncements_2025.1") === null ||
      localStorage.getItem("showLocationsAnnouncements_2025.1") === undefined
    )
      setViewPopup(true);
  }, []);

  const viewedPopup = () => {
    console.log("Viewed Popup");
    localStorage.setItem("showLocationsAnnouncements_2025.1", false);
    setViewPopup(false);
  };

  const classes = useStyles();
  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={viewPopup}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => viewedPopup()}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          simple
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          onClick={() => viewedPopup()}
        >
          {" "}
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>

      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <h2 className={classes.modalTitle}>
          Now Serving:
          <br /> Mississippi and Louisiana!
        </h2>
        <Button
          color="primary"
          round
          href="/locations/"
          onClick={() => viewedPopup()}
        >
          <Explore className={classes.icons} /> Come see our locations
        </Button>
      </DialogContent>
    </Dialog>
  );
}
